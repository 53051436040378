import React from "react";

const Logo = () => {
  const image = (
    <svg
      id="logo"
      width="734"
      height="106"
      viewBox="0 0 734 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="path-1-outside-1"
        maskUnits="userSpaceOnUse"
        x="0.0880127"
        y="0.335999"
        width="734"
        height="105"
        fill="black"
      >
        <rect
          fill="white"
          x="0.0880127"
          y="0.335999"
          width="734"
          height="105"
        />
        <path d="M102.896 3.352V103H89.792V28.696L56.672 103H47.456L14.192 28.552V103H1.08801V3.352H15.2L52.064 85.72L88.928 3.352H102.896Z" />
        <path d="M184.447 80.68H140.671L132.607 103H118.783L155.071 3.208H170.191L206.335 103H192.511L184.447 80.68ZM180.703 70.024L162.559 19.336L144.415 70.024H180.703Z" />
        <path d="M274.999 103L251.095 61.96H235.255V103H222.151V2.632H254.551C262.135 2.632 268.519 3.928 273.703 6.52C278.983 9.112 282.918 12.616 285.51 17.032C288.102 21.448 289.398 26.488 289.398 32.152C289.398 39.064 287.382 45.16 283.35 50.44C279.414 55.72 273.463 59.224 265.495 60.952L290.695 103H274.999ZM235.255 51.448H254.551C261.655 51.448 266.983 49.72 270.535 46.264C274.087 42.712 275.862 38.008 275.862 32.152C275.862 26.2 274.087 21.592 270.535 18.328C267.079 15.064 261.751 13.432 254.551 13.432H235.255V51.448Z" />
        <path d="M304.864 52.744C304.864 42.952 307.072 34.168 311.488 26.392C315.904 18.52 321.904 12.376 329.488 7.96C337.168 3.544 345.664 1.336 354.976 1.336C365.92 1.336 375.472 3.976 383.632 9.256C391.792 14.536 397.744 22.024 401.488 31.72H385.792C383.008 25.672 378.976 21.016 373.696 17.752C368.512 14.488 362.272 12.856 354.976 12.856C347.968 12.856 341.68 14.488 336.112 17.752C330.544 21.016 326.176 25.672 323.008 31.72C319.84 37.672 318.256 44.68 318.256 52.744C318.256 60.712 319.84 67.72 323.008 73.768C326.176 79.72 330.544 84.328 336.112 87.592C341.68 90.856 347.968 92.488 354.976 92.488C362.272 92.488 368.512 90.904 373.696 87.736C378.976 84.472 383.008 79.816 385.792 73.768H401.488C397.744 83.368 391.792 90.808 383.632 96.088C375.472 101.272 365.92 103.864 354.976 103.864C345.664 103.864 337.168 101.704 329.488 97.384C321.904 92.968 315.904 86.872 311.488 79.096C307.072 71.32 304.864 62.536 304.864 52.744Z" />
        <path d="M466.498 104.008C457.186 104.008 448.69 101.848 441.01 97.528C433.33 93.112 427.234 87.016 422.722 79.24C418.306 71.368 416.098 62.536 416.098 52.744C416.098 42.952 418.306 34.168 422.722 26.392C427.234 18.52 433.33 12.424 441.01 8.104C448.69 3.688 457.186 1.48 466.498 1.48C475.906 1.48 484.45 3.688 492.13 8.104C499.81 12.424 505.858 18.472 510.274 26.248C514.69 34.024 516.898 42.856 516.898 52.744C516.898 62.632 514.69 71.464 510.274 79.24C505.858 87.016 499.81 93.112 492.13 97.528C484.45 101.848 475.906 104.008 466.498 104.008ZM466.498 92.632C473.506 92.632 479.794 91 485.362 87.736C491.026 84.472 495.442 79.816 498.61 73.768C501.874 67.72 503.506 60.712 503.506 52.744C503.506 44.68 501.874 37.672 498.61 31.72C495.442 25.672 491.074 21.016 485.506 17.752C479.938 14.488 473.602 12.856 466.498 12.856C459.394 12.856 453.058 14.488 447.49 17.752C441.922 21.016 437.506 25.672 434.242 31.72C431.074 37.672 429.49 44.68 429.49 52.744C429.49 60.712 431.074 67.72 434.242 73.768C437.506 79.816 441.922 84.472 447.49 87.736C453.154 91 459.49 92.632 466.498 92.632Z" />
        <path d="M604.412 104.008C597.788 104.008 591.836 102.856 586.556 100.552C581.372 98.152 577.292 94.888 574.316 90.76C571.34 86.536 569.804 81.688 569.708 76.216H583.676C584.156 80.92 586.076 84.904 589.436 88.168C592.892 91.336 597.884 92.92 604.412 92.92C610.652 92.92 615.548 91.384 619.1 88.312C622.748 85.144 624.572 81.112 624.572 76.216C624.572 72.376 623.516 69.256 621.404 66.856C619.292 64.456 616.652 62.632 613.484 61.384C610.316 60.136 606.044 58.792 600.668 57.352C594.044 55.624 588.716 53.896 584.684 52.168C580.748 50.44 577.34 47.752 574.46 44.104C571.676 40.36 570.284 35.368 570.284 29.128C570.284 23.656 571.676 18.808 574.46 14.584C577.244 10.36 581.132 7.09599 586.124 4.792C591.212 2.488 597.02 1.336 603.548 1.336C612.956 1.336 620.636 3.688 626.588 8.392C632.636 13.096 636.044 19.336 636.812 27.112H622.412C621.932 23.272 619.916 19.912 616.364 17.032C612.812 14.056 608.108 12.568 602.252 12.568C596.78 12.568 592.316 14.008 588.86 16.888C585.404 19.672 583.676 23.608 583.676 28.696C583.676 32.344 584.684 35.32 586.7 37.624C588.812 39.928 591.356 41.704 594.332 42.952C597.404 44.104 601.676 45.448 607.148 46.984C613.772 48.808 619.1 50.632 623.132 52.456C627.164 54.184 630.62 56.92 633.5 60.664C636.38 64.312 637.82 69.304 637.82 75.64C637.82 80.536 636.524 85.144 633.932 89.464C631.34 93.784 627.5 97.288 622.412 99.976C617.324 102.664 611.324 104.008 604.412 104.008Z" />
        <path d="M669.92 2.632V66.136C669.92 75.064 672.08 81.688 676.4 86.008C680.816 90.328 686.912 92.488 694.688 92.488C702.368 92.488 708.368 90.328 712.688 86.008C717.104 81.688 719.312 75.064 719.312 66.136V2.632H732.416V65.992C732.416 74.344 730.736 81.4 727.376 87.16C724.016 92.824 719.456 97.048 713.696 99.832C708.032 102.616 701.648 104.008 694.544 104.008C687.44 104.008 681.008 102.616 675.248 99.832C669.584 97.048 665.072 92.824 661.712 87.16C658.448 81.4 656.816 74.344 656.816 65.992V2.632H669.92Z" />
      </mask>
      <path
        d="M102.896 3.352V103H89.792V28.696L56.672 103H47.456L14.192 28.552V103H1.08801V3.352H15.2L52.064 85.72L88.928 3.352H102.896Z"
        stroke="white"
        strokeWidth="2"
        mask="url(#path-1-outside-1)"
      />
      <path
        d="M184.447 80.68H140.671L132.607 103H118.783L155.071 3.208H170.191L206.335 103H192.511L184.447 80.68ZM180.703 70.024L162.559 19.336L144.415 70.024H180.703Z"
        stroke="white"
        strokeWidth="2"
        mask="url(#path-1-outside-1)"
      />
      <path
        d="M274.999 103L251.095 61.96H235.255V103H222.151V2.632H254.551C262.135 2.632 268.519 3.928 273.703 6.52C278.983 9.112 282.918 12.616 285.51 17.032C288.102 21.448 289.398 26.488 289.398 32.152C289.398 39.064 287.382 45.16 283.35 50.44C279.414 55.72 273.463 59.224 265.495 60.952L290.695 103H274.999ZM235.255 51.448H254.551C261.655 51.448 266.983 49.72 270.535 46.264C274.087 42.712 275.862 38.008 275.862 32.152C275.862 26.2 274.087 21.592 270.535 18.328C267.079 15.064 261.751 13.432 254.551 13.432H235.255V51.448Z"
        stroke="white"
        strokeWidth="2"
        mask="url(#path-1-outside-1)"
      />
      <path
        d="M304.864 52.744C304.864 42.952 307.072 34.168 311.488 26.392C315.904 18.52 321.904 12.376 329.488 7.96C337.168 3.544 345.664 1.336 354.976 1.336C365.92 1.336 375.472 3.976 383.632 9.256C391.792 14.536 397.744 22.024 401.488 31.72H385.792C383.008 25.672 378.976 21.016 373.696 17.752C368.512 14.488 362.272 12.856 354.976 12.856C347.968 12.856 341.68 14.488 336.112 17.752C330.544 21.016 326.176 25.672 323.008 31.72C319.84 37.672 318.256 44.68 318.256 52.744C318.256 60.712 319.84 67.72 323.008 73.768C326.176 79.72 330.544 84.328 336.112 87.592C341.68 90.856 347.968 92.488 354.976 92.488C362.272 92.488 368.512 90.904 373.696 87.736C378.976 84.472 383.008 79.816 385.792 73.768H401.488C397.744 83.368 391.792 90.808 383.632 96.088C375.472 101.272 365.92 103.864 354.976 103.864C345.664 103.864 337.168 101.704 329.488 97.384C321.904 92.968 315.904 86.872 311.488 79.096C307.072 71.32 304.864 62.536 304.864 52.744Z"
        stroke="white"
        strokeWidth="2"
        mask="url(#path-1-outside-1)"
      />
      <path
        d="M466.498 104.008C457.186 104.008 448.69 101.848 441.01 97.528C433.33 93.112 427.234 87.016 422.722 79.24C418.306 71.368 416.098 62.536 416.098 52.744C416.098 42.952 418.306 34.168 422.722 26.392C427.234 18.52 433.33 12.424 441.01 8.104C448.69 3.688 457.186 1.48 466.498 1.48C475.906 1.48 484.45 3.688 492.13 8.104C499.81 12.424 505.858 18.472 510.274 26.248C514.69 34.024 516.898 42.856 516.898 52.744C516.898 62.632 514.69 71.464 510.274 79.24C505.858 87.016 499.81 93.112 492.13 97.528C484.45 101.848 475.906 104.008 466.498 104.008ZM466.498 92.632C473.506 92.632 479.794 91 485.362 87.736C491.026 84.472 495.442 79.816 498.61 73.768C501.874 67.72 503.506 60.712 503.506 52.744C503.506 44.68 501.874 37.672 498.61 31.72C495.442 25.672 491.074 21.016 485.506 17.752C479.938 14.488 473.602 12.856 466.498 12.856C459.394 12.856 453.058 14.488 447.49 17.752C441.922 21.016 437.506 25.672 434.242 31.72C431.074 37.672 429.49 44.68 429.49 52.744C429.49 60.712 431.074 67.72 434.242 73.768C437.506 79.816 441.922 84.472 447.49 87.736C453.154 91 459.49 92.632 466.498 92.632Z"
        stroke="white"
        strokeWidth="2"
        mask="url(#path-1-outside-1)"
      />
      <path
        d="M604.412 104.008C597.788 104.008 591.836 102.856 586.556 100.552C581.372 98.152 577.292 94.888 574.316 90.76C571.34 86.536 569.804 81.688 569.708 76.216H583.676C584.156 80.92 586.076 84.904 589.436 88.168C592.892 91.336 597.884 92.92 604.412 92.92C610.652 92.92 615.548 91.384 619.1 88.312C622.748 85.144 624.572 81.112 624.572 76.216C624.572 72.376 623.516 69.256 621.404 66.856C619.292 64.456 616.652 62.632 613.484 61.384C610.316 60.136 606.044 58.792 600.668 57.352C594.044 55.624 588.716 53.896 584.684 52.168C580.748 50.44 577.34 47.752 574.46 44.104C571.676 40.36 570.284 35.368 570.284 29.128C570.284 23.656 571.676 18.808 574.46 14.584C577.244 10.36 581.132 7.09599 586.124 4.792C591.212 2.488 597.02 1.336 603.548 1.336C612.956 1.336 620.636 3.688 626.588 8.392C632.636 13.096 636.044 19.336 636.812 27.112H622.412C621.932 23.272 619.916 19.912 616.364 17.032C612.812 14.056 608.108 12.568 602.252 12.568C596.78 12.568 592.316 14.008 588.86 16.888C585.404 19.672 583.676 23.608 583.676 28.696C583.676 32.344 584.684 35.32 586.7 37.624C588.812 39.928 591.356 41.704 594.332 42.952C597.404 44.104 601.676 45.448 607.148 46.984C613.772 48.808 619.1 50.632 623.132 52.456C627.164 54.184 630.62 56.92 633.5 60.664C636.38 64.312 637.82 69.304 637.82 75.64C637.82 80.536 636.524 85.144 633.932 89.464C631.34 93.784 627.5 97.288 622.412 99.976C617.324 102.664 611.324 104.008 604.412 104.008Z"
        stroke="white"
        strokeWidth="2"
        mask="url(#path-1-outside-1)"
      />
      <path
        d="M669.92 2.632V66.136C669.92 75.064 672.08 81.688 676.4 86.008C680.816 90.328 686.912 92.488 694.688 92.488C702.368 92.488 708.368 90.328 712.688 86.008C717.104 81.688 719.312 75.064 719.312 66.136V2.632H732.416V65.992C732.416 74.344 730.736 81.4 727.376 87.16C724.016 92.824 719.456 97.048 713.696 99.832C708.032 102.616 701.648 104.008 694.544 104.008C687.44 104.008 681.008 102.616 675.248 99.832C669.584 97.048 665.072 92.824 661.712 87.16C658.448 81.4 656.816 74.344 656.816 65.992V2.632H669.92Z"
        stroke="white"
        strokeWidth="2"
        mask="url(#path-1-outside-1)"
      />
    </svg>
  );

  return <>{image}</>;
};

export default Logo;
